/* Botón para subir archivos */

.uploadcare--widget__button_type_open {
    background: #ededed;
    color: #000;
    font-size: 16px !important;
}

.uploadcare--widget__button_type_open:focus, .uploadcare--widget__button_type_open:hover {
    background: #d4d4d4;
    color: #000;
    
}

.uploadcare--widget__button:focus {
    outline: 2px solid rgba(163, 163, 163, 0.5) ;
}


.uploadcare--widget__button,
.uploadcare--widget__dragndrop-area,
.uploadcare--progress,
.uploadcare--widget__text {
  display: none;
}