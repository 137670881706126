@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-Regular.ttf) format('truetype');
  font-weight: 400;
}

@font-face {
  font-family: 'Poppins';
  src: local('Poppins'), url(./statics/fuentes/Poppins/Poppins-Medium.ttf) format('truetype');
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}



span{
  font-weight: 300;
}

li{
  font-weight: 300;
}

td{
  font-weight: 300;
}

th{
  font-weight: 600;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.listaprospectos {
  display: flex;
  flex-direction: column;
  cursor: pointer !important;
  background: #ffff;
  padding: 10px;
  border-radius: 10px;
  margin-top: 10px;
}

.ProspectosGeneral {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.cardProspectosGeneral {
  padding: 20px;
  text-align: center;
  margin: 10px;
  background: #f6f7f8;
  border-radius: 10px;

}